/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//TODO:
require('./src/styles/global.scss');
const React = require('react');
const fullstory = require('@fullstory/snippet');
// You can delete this file if you're not using it
let isJobPost = false;
exports.onRenderBody = ({ setHeadComponents }) => {
  if (typeof window !== 'undefined') {
    isJobPost = window?.location?.pathname.indexOf('careers/jobs') > -1;
  }

  setHeadComponents(
    <script
      dangerouslySetInnerHTML={{
        __html: `
            window['_fs_run_in_iframe'] = ${isJobPost};
            window['_fs_debug'] = false;
            window['_fs_host'] = 'staging.fullstory.com';
            window['_fs_script'] = 'edge.staging.fullstory.com/s/fs.js';
            window['_fs_org'] = 'thefullstory.com';
            window['_fs_namespace'] = 'FS';
            ${fullstory.SnippetSrc}
        `,
      }}
    />
  );
};

exports.shouldUpdateScroll = () => {
  if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
    return false;
  }
  window.scrollTo(0, 0);

  return false;
};
